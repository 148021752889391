<template>
  <div>
  </div>
</template>

<script>
import AxiosBase from '../../api/AxiosBase'

export default {
  name: 'Logout',
  created() {
    localStorage.removeItem('jwt');
    localStorage.removeItem('username');

    AxiosBase.post("/auth/logout", {
      refresh_token: localStorage.getItem('refresh_token')
    })
    .catch(function(error) {
      console.log(error);
    })
    .then(function(res) {
      console.log(res);
      localStorage.removeItem('refresh_token');
      window.location.href = process.env.VUE_APP_LOGIN_URL;
    });
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
